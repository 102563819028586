<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="wrap1">
                <Spinner />
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import AppWrapper from '@/layout/AppWrapper'
import Spinner from '@/components/Spinner'
import { onBeforeMount } from '@vue/runtime-core'

export default {
    name: 'VerifyEmail',
    components: { AppWrapper, Spinner },

    setup() {
        const store = useStore()
        const route = useRoute()

        onBeforeMount(() => {
            const { token } = route.params
            store.dispatch('Auth/verifyEmail', token)
        })
    },
}
</script>

<style scoped>

</style>
